import React from 'react';
import { motion } from 'framer-motion';
import { Search, Map, Presentation, Rocket, LineChart } from 'lucide-react';

const ApproachStep = ({ icon: Icon, title, description, index, timeline, deliverables }) => (
  <motion.div 
    className="relative group h-full"
    whileHover={{ y: -2 }}
  >
    <div className="absolute -inset-0.5 bg-gradient-to-r from-blue-500/20 to-blue-600/20 rounded-lg blur opacity-0 group-hover:opacity-75 transition duration-500" />
    <div className="relative h-full bg-gradient-to-br from-gray-900 to-blue-950 p-8 rounded-lg border border-blue-200/10 hover:border-blue-400/30 transition-colors duration-300">
      <div className="absolute bottom-6 right-6 text-4xl font-light text-blue-500/20">
        {(index + 1).toString().padStart(2, '0')}
      </div>

      <div className="flex items-center gap-4 mb-6">
        <div className="p-3 rounded-lg bg-blue-500/10">
          <Icon className="w-6 h-6 text-blue-300" />
        </div>
        <h3 className="text-xl font-light text-white">
          {title}
        </h3>
      </div>

      <p className="text-blue-100/70 font-light leading-relaxed mb-6">
        {description}
      </p>

      <div className="space-y-4 mt-auto">
        <div>
          <div className="text-sm text-blue-300/60 mb-2">Timeline</div>
          <div className="text-blue-100 font-light">{timeline}</div>
        </div>
        
        <div>
          <div className="text-sm text-blue-300/60 mb-2">Key Deliverables</div>
          <ul className="space-y-2">
            {deliverables.map((deliverable, idx) => (
              <li key={idx} className="flex items-center gap-2 text-sm text-blue-100/70">
                <div className="w-1 h-1 rounded-full bg-blue-400/40" />
                {deliverable}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  </motion.div>
);

const Approach = () => {
  const steps = [
    {
      icon: Search,
      title: 'Executive Discovery',
      description: 'Comprehensive analysis of your organization\'s AI readiness, challenges, and strategic objectives through executive workshops and stakeholder interviews.',
      timeline: '2-3 Weeks',
      deliverables: [
        'Current State Assessment',
        'Executive Alignment Report',
        'Strategic Opportunity Map'
      ]
    },
    {
      icon: Map,
      title: 'Strategic Roadmapping',
      description: 'Development of a detailed implementation roadmap that aligns GenAI initiatives with your business strategy and compliance requirements.',
      timeline: '3-4 Weeks',
      deliverables: [
        'Strategic Roadmap',
        'Resource Requirements',
        'Risk Mitigation Plan'
      ]
    },
    {
      icon: Presentation,
      title: 'Stakeholder Alignment',
      description: 'Securing organizational buy-in through targeted engagement with key stakeholders and detailed implementation planning.',
      timeline: '2-3 Weeks',
      deliverables: [
        'Stakeholder Matrix',
        'Communication Strategy',
        'Change Management Plan'
      ]
    },
    {
      icon: Rocket,
      title: 'Phased Implementation',
      description: 'Structured rollout of GenAI initiatives, prioritizing high-impact, low-risk opportunities while building toward comprehensive transformation.',
      timeline: '3-6 Months',
      deliverables: [
        'Implementation Schedule',
        'Success Metrics Framework',
        'Progress Reporting Templates'
      ]
    },
    {
      icon: LineChart,
      title: 'Value Realization',
      description: 'Continuous monitoring and optimization of implemented solutions to ensure maximum ROI and strategic value creation.',
      timeline: 'Ongoing',
      deliverables: [
        'ROI Analysis Dashboard',
        'Performance Optimization Plan',
        'Value Realization Report'
      ]
    }
  ];

  return (
    <section className="py-24 bg-gradient-to-br from-gray-900 via-black to-blue-950">
      <div className="container mx-auto px-6">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="max-w-3xl mx-auto text-center mb-16"
        >
          <h2 className="text-4xl font-light text-white mb-6">
            Implementation Approach
          </h2>
          <p className="text-xl text-blue-100/70 font-light">
            A proven methodology refined through Fortune 500 implementations
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {steps.map((step, index) => (
            <motion.div
              key={step.title}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <ApproachStep {...step} index={index} />
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Approach;