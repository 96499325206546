import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Database, BookOpen, Briefcase, FileText, LineChart, Phone, Users, Building2, X } from 'lucide-react';

const StoryCard = ({ story, onClick }) => (
  <motion.div 
    onClick={onClick}
    className="relative group cursor-pointer h-full"
    whileHover={{ y: -2 }}
  >
    <div className="h-full bg-gradient-to-br from-gray-900 to-blue-950 rounded-lg border border-blue-200/10 overflow-hidden transition-all duration-300 hover:border-blue-400/30">
      <div className="p-8">
        <div className="flex items-center gap-4 mb-6">
          <div className="p-3 rounded-lg bg-blue-500/10">
            <story.icon className="w-6 h-6 text-blue-300" />
          </div>
          <div>
            <div className="text-sm text-blue-300/60 mb-1">{story.industry}</div>
            <h3 className="text-xl font-light text-white">
              {story.title}
            </h3>
          </div>
        </div>

        <div className="text-blue-100/70 font-light leading-relaxed">
          {story.challenge}
        </div>
      </div>
    </div>
  </motion.div>
);

const DetailModal = ({ story, onClose }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  const isMobile = window.innerWidth < 768;
  
  const variants = {
    mobile: {
      initial: { y: '100%' },
      animate: { y: '0%' },
      exit: { y: '100%' },
    },
    desktop: {
      initial: { opacity: 0, scale: 0.95 },
      animate: { opacity: 1, scale: 1 },
      exit: { opacity: 0, scale: 0.95 },
    }
  };

  return (
    <motion.div 
      className="fixed inset-0 bg-black/80 backdrop-blur-sm z-40 pt-20 md:pt-0 md:flex md:items-center md:justify-center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onClose();
        }
      }}
    >
      <motion.div 
        className={`
          fixed w-full bg-[#0A1017] overflow-y-auto
          ${isMobile ? 'bottom-0 rounded-t-2xl max-h-[calc(100vh-5rem)]' : 'relative rounded-xl max-w-3xl mx-4 max-h-[85vh]'}
        `}
        variants={isMobile ? variants.mobile : variants.desktop}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ type: 'spring', damping: 25, stiffness: 300 }}
      >
        <div className="p-6 md:p-8">
          {/* Header */}
          <div className="flex items-start justify-between">
            <div className="flex-1">
              <div className="text-sm text-blue-300/60 mb-5">{story.industry}</div>
              <div className="flex items-start gap-4 mb-10">
                <div className="p-3 rounded-lg bg-blue-500/10 shrink-0">
                  <story.icon className="w-8 h-8 text-blue-300" />
                </div>
                <h3 className="text-2xl font-light text-white flex-1">
                  {story.title}
                </h3>
              </div>
            </div>
            
            <button 
              onClick={onClose}
              className="p-2 -mt-1 -mr-2 text-blue-300/60 hover:text-white transition-colors"
            >
              <X className="w-6 h-6" />
            </button>
          </div>

          {/* Content */}
          <div className="space-y-8">
            {['challenge', 'solution', 'outcome'].map((section) => (
              <div key={section} className="space-y-3">
                <h4 className="text-lg font-medium text-blue-200 capitalize">
                  {section}
                </h4>
                <p className="text-blue-100/70 font-light leading-relaxed">
                  {story[section]}
                </p>
              </div>
            ))}
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

const SuccessStories = () => {
  const [selectedStory, setSelectedStory] = useState(null);

  const stories = [
    {
      icon: Database,
      title: "SQL Chatbot for Enhanced Data Interactions",
      industry: "Technology / Data Management",
      challenge: "A leading technology company was struggling with data accessibility. Their complex Redshift database was a treasure trove of insights, but it remained largely untapped due to the technical expertise required to query it effectively.",
      solution: "We developed a sophisticated SQL chatbot that acts as an intelligent intermediary between users and the Redshift database. This AI-powered solution leverages advanced natural language processing techniques to interpret user queries, regardless of their technical background.",
      outcome: "The implementation of the SQL chatbot transformed the company's data interaction landscape. Non-technical staff from various departments can now easily extract valuable insights from the database without requiring SQL knowledge."
    },
    {
      icon: BookOpen,
      title: "AI-Powered Content Generation for EdTech",
      industry: "Education Technology",
      challenge: "An innovative EdTech startup was facing a critical bottleneck in their content creation process. As they aimed to provide personalized learning experiences, the demand for diverse, high-quality educational materials was outpacing their ability to produce content using traditional methods.",
      solution: "We devised a comprehensive GenAI solution tailored for educational content generation. This involved developing sophisticated prompting techniques optimized for creating various types of educational materials, including lesson plans, quizzes, and explanatory texts.",
      outcome: "The GenAI solution revolutionized the startup's content creation capabilities. Content creators can now focus on high-level curriculum design and quality control, while the AI handles the bulk of initial content generation."
    },
    {
      icon: Briefcase,
      title: "GenAI Strategy for Enterprise Software",
      industry: "Enterprise Software",
      challenge: "An enterprise software startup was at a critical juncture in their growth journey. They recognized the transformative potential of Generative AI but lacked a coherent strategy to integrate it into their product offerings and internal processes.",
      solution: "We collaborated closely with the startup's leadership to develop a comprehensive GenAI strategy. This involved a deep analysis of their current product suite, market positioning, and internal capabilities.",
      outcome: "The GenAI strategy provided the startup with a clear direction for innovation and growth. It enabled them to prioritize GenAI initiatives that aligned closely with their business objectives and market demands."
    },
    {
      icon: FileText,
      title: "Unstructured Data Mapping Pipeline",
      industry: "Data Integration Services",
      challenge: "A data integration service provider was grappling with the increasing volume and variety of unstructured data their clients needed to process. Their existing methods were time-consuming and often required manual intervention, leading to delays and inconsistencies.",
      solution: "We developed an advanced pipeline leveraging GenAI technologies to automate the mapping of unstructured data to the company's internal data schema. This solution incorporated natural language processing and machine learning algorithms to understand, categorize, and structure incoming data from various sources.",
      outcome: "The implementation of this GenAI-powered pipeline transformed the company's data integration capabilities. The time required for data mapping and integration was significantly reduced, allowing the company to handle a much larger volume of data with greater speed and accuracy."
    },
    {
      icon: LineChart,
      title: "Equity Index Strategy Backtesting",
      industry: "Financial Services / Pension Fund",
      challenge: "A prominent pension fund was looking to optimize its investment strategies, particularly in equity index investments. They needed a robust way to evaluate potential strategies by backtesting them against historical data.",
      solution: "We developed a sophisticated web application specifically designed for backtesting equity index strategies. This application leveraged GenAI to process and analyze vast amounts of historical financial data.",
      outcome: "The backtesting web application revolutionized the pension fund's approach to strategy development and evaluation. Investment managers can now rapidly test and refine their ideas, leading to more robust and well-validated investment strategies."
    },
    {
      icon: Phone,
      title: "LLM Agent for Medical Device Support",
      industry: "Healthcare / Medical Devices",
      challenge: "A medical device company was facing increasing pressure on their customer support team. They needed to provide timely, accurate support to patients using their devices, but the volume of inquiries was overwhelming their human staff.",
      solution: "We created an advanced LLM agent capable of conducting voice calls with patients. This AI-powered solution was integrated with the company's product documentation and support databases, enabling it to provide accurate, up-to-date information.",
      outcome: "The implementation of the LLM agent significantly enhanced the company's customer support capabilities. It successfully handles a large volume of routine inquiries and follow-ups, freeing up human agents to focus on more complex cases."
    },
    {
      icon: Users,
      title: "Legal Interview & Document Automation",
      industry: "Legal Services",
      challenge: "A major US law firm needed to streamline their pre-litigation interview process and document preparation. The traditional approach was consuming significant attorney time on routine interviews and document creation, limiting the firm's capacity to handle more complex legal work.",
      solution: "Developed an LLM-based interview agent system that conducts initial client interviews and automatically generates pre-litigation documentation. The system integrates with the firm's case management platform and follows established legal interview protocols.",
      outcome: "The automation system successfully handles initial client interviews and generates accurate pre-litigation documentation, significantly reducing attorney time spent on routine tasks while maintaining high document quality and compliance standards."
    },
    {
      icon: Building2,
      title: "Store Labor Planning & Optimization",
      industry: "Retail",
      challenge: "A large US retailer struggled with labor allocation efficiency across their store network. Their existing planning process couldn't effectively balance staffing needs with profitability targets, leading to suboptimal store performance and unnecessary labor costs.",
      solution: "Created an integrated AI system combining predictive modeling for store profitability with an optimization engine for labor allocation. The solution analyzes historical performance data and local market conditions to generate optimal staffing recommendations.",
      outcome: "The system delivered significant improvements in store profitability through optimized labor allocation, enabling data-driven workforce planning while maintaining high service levels and operational efficiency."
    },
    {
      icon: Briefcase,
      title: "AI-Powered Executive Coaching Platform",
      industry: "Executive Leadership Development",
      challenge: "A renowned CEO coach with a distinctive methodology developed over decades of advising Fortune 500 leaders needed to scale his unique approach. Traditional coaching methods couldn't meet the growing demand while maintaining the depth and personalization of his proven framework.",
      solution: "We engineered an advanced AI platform that crystallized his methodology into an interactive system. The platform combines LLM technology with sophisticated scenario modeling to create dynamic coaching interactions, enabling leaders to explore strategic challenges and receive personalized insights.",
      outcome: "The platform successfully extended the coach's methodology to a broader audience while maintaining the depth of his approach. Executive trainees now engage in AI-driven ideation sessions that mirror his coaching style, leading to breakthrough insights and accelerated leadership development."
    },
    {
      icon: LineChart,
      title: "Demand Forecasting Center of Excellence",
      industry: "Conglomerate / Retail",
      challenge: "A major Caribbean conglomerate needed to establish sophisticated demand forecasting capabilities across their diverse portfolio of businesses. They faced the dual challenge of building advanced analytical capabilities while developing local talent in an emerging market.",
      solution: "We designed and implemented a comprehensive demand forecasting function, including custom-built forecasting models, specialized training curriculum for local talent, and a structured hiring and development program to create a sustainable center of excellence.",
      outcome: "Successfully established a world-class demand forecasting operation staffed by trained local talent. The new capability has transformed the conglomerate's planning accuracy, while creating a sustainable pipeline of skilled analysts in the region."
    }
];

  return (
    <section id="case-studies" className="py-24 bg-gradient-to-br from-gray-900 via-black to-blue-950">
      <div className="container mx-auto px-6">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="max-w-3xl mx-auto text-center mb-16"
        >
          <h2 className="text-4xl font-light text-white mb-6">
            Success Stories
          </h2>
          <p className="text-xl text-blue-100/70 font-light">
            Real results from industry-leading organizations
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {stories.map((story, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <StoryCard 
                story={story} 
                onClick={() => setSelectedStory(story)}
              />
            </motion.div>
          ))}
        </div>

        <AnimatePresence>
          {selectedStory && (
            <DetailModal 
              story={selectedStory} 
              onClose={() => setSelectedStory(null)}
            />
          )}
        </AnimatePresence>
      </div>
    </section>
  );
};

export default SuccessStories;