import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Calendar, Clock, Search } from 'lucide-react';
import { Link } from 'react-router-dom';
import { getAllPosts } from '../lib/contentful';

const categories = [
  'All',
  'Strategy',
  'Implementation',
  'Technology',
  'Case Studies',
  'Planning',
  'Generative AI',
];

const BlogPage = () => {
    const [error, setError] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [searchQuery, setSearchQuery] = useState('');
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      const loadPosts = async () => {
        try {
          setLoading(true);
          const response = await getAllPosts();
          // Transform the response using mapPost
          const formattedPosts = response.items.map(item => ({
            id: item.sys.id,
            slug: item.fields.slug,
            title: item.fields.title,
            excerpt: item.fields.excerpt || '',
            content: item.fields.content,
            category: item.fields.category || 'Uncategorized',
            author: item.fields.author?.fields || {
              name: 'Christian Adib',
              role: 'Founder and Managing Partner'
            },
            date: new Date(item.fields.publicationDate || item.sys.createdAt).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric'
            }),
            readTime: item.fields.readTime || 5,
            tags: item.fields.tags || []
          }));
          
          setPosts(formattedPosts);
        } catch (error) {
          console.error('Error details:', error);
          setError(error.message);
        } finally {
          setLoading(false);
        }
      };
  
      loadPosts();
    }, []);

  if (error) {
    return (
      <div className="bg-[#0A1017] min-h-screen mt-20 flex items-center justify-center">
        <div className="text-red-400">Error loading posts: {error}</div>
      </div>
    );
  }

  const filteredPosts = posts.filter(post => {
    const matchesCategory = selectedCategory === 'All' || post.category === selectedCategory;
    const matchesSearch = 
      post.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      post.excerpt.toLowerCase().includes(searchQuery.toLowerCase());
    return matchesCategory && matchesSearch;
  });

  if (loading) {
    return <div className="bg-[#0A1017] min-h-screen mt-20">Loading...</div>;
  }

  return (
    <div className="bg-[#0A1017] min-h-screen mt-20">
      <div className="bg-gradient-to-br from-gray-900 via-black to-blue-950 py-24">
        <div className="container mx-auto px-6">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="max-w-3xl mx-auto text-center"
          >
            <h1 className="text-4xl md:text-5xl font-light text-white mb-6">
              AI Insights
            </h1>
            <p className="text-xl text-blue-100/70 font-light">
              Expert perspectives on enterprise AI strategy, implementation, and value realization
            </p>
          </motion.div>
        </div>
      </div>

      <div className="border-b border-blue-900/20">
        <div className="container mx-auto px-6 py-6">
          <div className="flex flex-col md:flex-row gap-6 justify-between items-center">
            <div className="flex gap-4 overflow-x-auto pb-2 md:pb-0 w-full md:w-auto">
              {categories.map((category) => (
                <button
                  key={category}
                  onClick={() => setSelectedCategory(category)}
                  className={`
                    whitespace-nowrap px-4 py-2 rounded-md text-sm font-light transition-colors duration-300
                    ${selectedCategory === category 
                      ? 'bg-blue-600 text-white' 
                      : 'bg-white/5 text-gray-300 hover:bg-white/10'}
                  `}
                >
                  {category}
                </button>
              ))}
            </div>

            <div className="relative w-full md:w-64">
              <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
              <input
                type="text"
                placeholder="Search articles..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full bg-white/5 border border-blue-900/20 rounded-md py-2 pl-10 pr-4 text-white placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto px-6 py-16">
        {filteredPosts.length === 0 ? (
          <div className="text-center text-gray-400 py-12">
            <p className="text-lg mb-4">No articles found</p>
            <button
              onClick={() => {
                setSelectedCategory('All');
                setSearchQuery('');
              }}
              className="text-blue-400 hover:text-blue-300 transition-colors"
            >
              Clear filters
            </button>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {filteredPosts.map((post, index) => (
              <motion.div
                key={post.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <Link 
                  to={`/blog/${post.slug}`}
                  className="block h-full"
                >
                  <div className="h-full bg-gradient-to-br from-gray-900 to-blue-950 rounded-lg border border-blue-200/10 overflow-hidden transition-all duration-300 hover:border-blue-400/30 p-8">
                    <div className="text-sm text-blue-400 mb-4">{post.category}</div>
                    <h2 className="text-xl font-light text-white mb-4 group-hover:text-blue-300 transition-colors">
                      {post.title}
                    </h2>
                    <p className="text-blue-100/70 font-light leading-relaxed mb-6">
                      {post.excerpt}
                    </p>
                    <div className="mb-4">
                      <div className="text-white font-light">{post.author.name}</div>
                      <div className="text-sm text-blue-200/60">{post.author.role}</div>
                    </div>
                    <div className="flex items-center gap-4 text-sm text-blue-200/60">
                      <div className="flex items-center gap-2">
                        <Calendar className="w-4 h-4" />
                        {post.date}
                      </div>
                      <div className="flex items-center gap-2">
                        <Clock className="w-4 h-4" />
                        {post.readTime} min read
                      </div>
                    </div>
                  </div>
                </Link>
              </motion.div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default BlogPage;