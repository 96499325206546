import React from 'react';
import { Link } from 'react-router-dom';
import { Linkedin, Twitter } from 'lucide-react';

const Footer = () => (
  <footer className="bg-[#0A1017] text-white py-12 md:py-16 border-t border-blue-900/20">
    <div className="container mx-auto px-4 md:px-16">
      <div className="flex flex-col md:flex-row md:justify-between gap-12 md:gap-8 mb-12 md:mb-16">
        {/* Company Info */}
        <div className="space-y-8 md:max-w-sm">
          <div>
            <img 
              src="/logo.png" 
              alt="Fulkerson Advisors" 
              className="h-8 w-auto mb-6"
            />
            <div className="text-gray-400 text-sm">
              AI strategy and implementation for Fortune 500 organizations.
            </div>
          </div>
          
          <div>
            <div className="text-sm text-blue-400 mb-2">Follow Us</div>
            <div className="flex gap-4">
              <a 
                href="https://www.linkedin.com/company/fulkerson-advisors" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-gray-400 hover:text-blue-400 transition-colors duration-300"
              >
                <Linkedin size={20} />
              </a>
              <a 
                href="https://twitter.com/fulkadvisors" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-gray-400 hover:text-blue-400 transition-colors duration-300"
              >
                <Twitter size={20} />
              </a>
            </div>
          </div>
        </div>

        {/* Contact Information */}
        <div className="space-y-6">
          <div>
            <div className="text-sm text-blue-400 mb-2">Address</div>
            <div className="text-gray-400">
              <p>PO Box 410127</p>
              <p>Cambridge, MA 02141</p>
            </div>
          </div>
          
          <div>
            <div className="text-sm text-blue-400 mb-2">Contact</div>
            <div className="text-gray-400">
              <a 
                href="mailto:info@fulkersonadvisors.com" 
                className="hover:text-blue-400 transition-colors duration-300"
              >
                info@fulkersonadvisors.com
              </a>
              <p className="mt-1">(617) 751-9068</p>
            </div>
          </div>
        </div>


      </div>

      {/* Bottom Bar */}
      <div className="pt-8 border-t border-blue-900/20 flex flex-col md:flex-row justify-between items-center gap-4">
        <div className="text-sm text-gray-500 text-center md:text-left">
          &copy; 2024 Fulkerson Advisors. All rights reserved.
        </div>
        
        <div className="flex gap-6 md:gap-8 text-sm text-gray-500">
          <Link 
            to="/privacy" 
            className="hover:text-blue-400 transition-colors duration-300"
          >
            Privacy Policy
          </Link>
          <Link 
            to="/terms" 
            className="hover:text-blue-400 transition-colors duration-300"
          >
            Terms of Service
          </Link>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;